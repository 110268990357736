<template>
  <div id="modules">
    <mainheader></mainheader>
    <ChooseTariffModal></ChooseTariffModal>
    <mainbox></mainbox>
    <section>
      <div class="modul-block">
        <div class="container">
          <h1>{{ translation.translate("Modules", "moduls-title") }}</h1>
          <div id="accordion" class="Modules--block">
            <div class="row">
              <div class="col-lg-6">
                <div class="col-lg-12">
                  <div class="card card-100 card-radius-modul">
                    <div class="card-header card-accoridionModul background-archive-modules" id="headingOne">
                      <h5 class="mb-0">
                        <div class="row">
                          <div class="col-lg-2 col-md-2 col-sm-2 mr-15">
                            <img src="../assets/images/1c.png" alt="png" />
                          </div>
                          <div class="col-lg-9 col-md-9 col-sm-9 p-20-1199">
                            <h4 class="title-modul">
                              Бухгалтерия, Хозрасчет, редакция 1.3
                            </h4>
                            <h6>Версия: 3.7</h6>
                            <p class="info-modul">
                              {{ translation.translate("Modules", "information-passage-1_v3.6.9") }}
                            </p>
                            <div class="d-flex justify-content-end">
                              <div>
                                <button class=" btn-More-details" type="button" data-toggle="collapse"
                                  data-target="#collapseArchive" aria-expanded="false" aria-controls="collapseArchive">
                                  Archive
                                </button>
                              </div>
                              <div>
                                <button class=" btn-More-details" type="button" data-toggle="collapse"
                                  data-target="#collapseExample" aria-expanded="false" aria-controls="collapseExample">
                                  {{ translation.translate("Modules", "btn-more-info") }}
                                </button>
                              </div>
                              <div>
                                <a href="https://edo.faktura.uz/resource/Faktura_uz_ЭДО_Версия_3_7.zip" download
                                  class=" btn-download-1c">{{ translation.translate("Resources", "download-btn") }}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </h5>
                    </div>

                    <div class="collapse" id="collapseExample">
                      <div class="card card-body shadow-none">
                        <p class="info-modul">
                          {{
                            translation.translate(
                              "Modules",
                              "information-passage-2_v3.6.9"
                            )
                          }}
                        </p>
                        <p class="info-modul">
                          {{
                            translation.translate(
                              "Modules",
                              "information-passage-3_v3.6.9"
                            )
                          }}
                        </p>
                        <p class="info-modul">
                          {{
                            translation.translate(
                              "Modules",
                              "information-passage-4_v3.6.9"
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="collapse" id="collapseArchive">
                      <div class="card-body">
                        <div class="col-lg-12 mt-3">
                          <div class="card cardArchive card-radius-modul">
                            <div class="card-header card-accoridionModul background-archive-modules" id="headingOne">
                              <h5 class="mb-0">
                                <div class="row">
                                  <div class="col-lg-2 col-md-2 col-sm-2 mr-15">
                                    <img src="../assets/images/1c.png" alt="png" />
                                  </div>
                                  <div class="col-lg-9 col-md-9 col-sm-9 p-20-1199">
                                    <h4 class="title-modul">
                                      Бухгалтерия, Хозрасчет, редакция 1.3
                                    </h4>
                                    <h6>Версия: 3.6.9</h6>
                                    <p class="info-modul">
                                      {{ translation.translate("Modules", "information-passage-1") }}
                                    </p>
                                    <div class="d-flex justify-content-end">
                                      <div>
                                      </div>
                                      <div>
                                        <button class=" btn-More-details" type="button" data-toggle="collapse"
                                          data-target="#collapseExample3-6-9" aria-expanded="false"
                                          aria-controls="collapseExample3-6-9">
                                          {{ translation.translate("Modules", "btn-more-info") }}
                                        </button>
                                      </div>
                                      <div>
                                        <a href="https://edo.faktura.uz/resource/Faktura_uz_ЭДО_Версия_3.6.9.zip" download
                                          class=" btn-download-1c">{{ translation.translate("Resources", "download-btn") }}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </h5>
                            </div>

                            <div class="collapse" id="collapseExample3-6-9">
                              <div class="card card-body shadow-none">
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage-2') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage-3') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage-4') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage-5') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage-6') }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 mt-3">
                          <div class="card cardArchive card-radius-modul">
                            <div class="card-header card-accoridionModul background-archive-modules" id="headingOne">
                              <h5 class="mb-0">
                                <div class="row">
                                  <div class="col-lg-2 col-md-2 col-sm-2 mr-15">
                                    <img src="../assets/images/1c.png" alt="png" />
                                  </div>
                                  <div class="col-lg-9 col-md-9 col-sm-9 p-20-1199">
                                    <h4 class="title-modul">
                                      Бухгалтерия, Хозрасчет, редакция 1.3
                                    </h4>
                                    <h6>Версия: 3.6.8</h6>
                                    <p class="info-modul">
                                      {{ translation.translate("Modules", "information-passage-1") }}
                                    </p>
                                    <div class="d-flex justify-content-end">
                                      <div>
                                      </div>
                                      <div>
                                        <button class=" btn-More-details" type="button" data-toggle="collapse"
                                          data-target="#collapseExample3-6-8" aria-expanded="false"
                                          aria-controls="collapseExample3-6-8">
                                          {{ translation.translate("Modules", "btn-more-info") }}
                                        </button>
                                      </div>
                                      <div>
                                        <a href="https://edo.faktura.uz/resource/Faktura_uz_Module_3.6.8_fat.zip" download
                                          class=" btn-download-1c">{{ translation.translate("Resources", "download-btn") }}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </h5>
                            </div>

                            <div class="collapse" id="collapseExample3-6-8">
                              <div class="card card-body shadow-none">
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage-2') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage-3') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage-4') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage-5') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage-6') }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 mt-3">
                          <div class="card cardArchive card-radius-modul">
                            <div class="card-header card-accoridionModul background-archive-modules" id="headingOne">
                              <h5 class="mb-0">
                                <div class="row">
                                  <div class="col-lg-2 col-md-2 col-sm-2 mr-15">
                                    <img src="../assets/images/1c.png" alt="png" />
                                  </div>
                                  <div class="col-lg-9 col-md-9 col-sm-9 p-20-1199">
                                    <h4 class="title-modul">
                                      Бухгалтерия, Хозрасчет, редакция 1.3
                                    </h4>
                                    <h6>Версия: 3.6</h6>
                                    <p class="info-modul">
                                      {{ translation.translate("Modules", "information-passage-1") }}
                                    </p>
                                    <div class="d-flex justify-content-end">
                                      <div>
                                      </div>
                                      <div>
                                        <button class=" btn-More-details" type="button" data-toggle="collapse"
                                          data-target="#collapseExample3-6" aria-expanded="false"
                                          aria-controls="collapseExample3-6">
                                          {{ translation.translate("Modules", "btn-more-info") }}
                                        </button>
                                      </div>
                                      <div>
                                        <a href="https://edo.faktura.uz/resource/Faktura_uz_Module_3.6_fat.zip" download
                                          class=" btn-download-1c">{{ translation.translate("Resources", "download-btn") }}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </h5>
                            </div>

                            <div class="collapse" id="collapseExample3-6">
                              <div class="card card-body shadow-none">
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-2') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-3') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-4') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-5') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-6') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-7') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-8') }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 mt-3">
                          <div class="card cardArchive card-radius-modul">
                            <div class="card-header card-accoridionModul background-archive-modules" id="headingOne">
                              <h5 class="mb-0">
                                <div class="row">
                                  <div class="col-lg-2 col-md-2 col-sm-2 mr-15">
                                    <img src="../assets/images/1c.png" alt="png" />
                                  </div>
                                  <div class="col-lg-9 col-md-9 col-sm-9 p-20-1199">
                                    <h4 class="title-modul">
                                      Бухгалтерия, Хозрасчет, редакция 1.3
                                    </h4>
                                    <h6>Версия: 3.5</h6>
                                    <p class="info-modul">
                                      {{ translation.translate('Modules', 'information-passage2-1') }}
                                    </p>
                                    <div class="d-flex justify-content-end">
                                      <div>
                                        <button class=" btn-More-details" type="button" data-toggle="collapse"
                                          data-target="#collapseExample1" aria-expanded="false"
                                          aria-controls="collapseExample1">
                                          {{ translation.translate("Modules", "btn-more-info") }}
                                        </button>
                                      </div>
                                      <div>
                                        <a href="https://edo.faktura.uz/resource/Faktura_uz_Module_3.5_fat.zip" download
                                          class=" btn-download-1c">{{ translation.translate("Resources", "download-btn") }}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </h5>
                            </div>

                            <div class="collapse" id="collapseExample1">
                              <div class="card card-body shadow-none">
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-2') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-3') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-4') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-5') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-6') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-7') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-8') }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 mt-3">
                          <div class="card cardArchive card-radius-modul">
                            <div class="card-header card-accoridionModul background-archive-modules" id="headingOne">
                              <h5 class="mb-0">
                                <div class="row">
                                  <div class="col-lg-2 col-md-2 col-sm-2 mr-15">
                                    <img src="../assets/images/1c.png" alt="png" />
                                  </div>
                                  <div class="col-lg-9 col-md-9 col-sm-9 p-20-1199">
                                    <h4 class="title-modul">
                                      Бухгалтерия, Хозрасчет, редакция 1.3
                                    </h4>
                                    <h6>Версия: 3.4</h6>
                                    <p class="info-modul">
                                      {{ translation.translate('Modules', 'information-passage3-1') }}
                                    </p>
                                    <div class="d-flex justify-content-end">
                                      <div>
                                        <button class=" btn-More-details" type="button" data-toggle="collapse"
                                          data-target="#collapseExample2" aria-expanded="false"
                                          aria-controls="collapseExample2">
                                          {{ translation.translate("Modules", "btn-more-info") }}
                                        </button>
                                      </div>
                                      <div>
                                        <a href="https://edo.faktura.uz/resource/Faktura_uz_Module_3.4_fat.zip" download
                                          class=" btn-download-1c">{{ translation.translate("Resources", "download-btn") }}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </h5>
                            </div>

                            <div class="collapse" id="collapseExample2">
                              <div class="card card-body shadow-none">
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage3-2') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage3-3') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage3-4') }} </p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage3-5') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage3-6') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage3-7') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage3-8') }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 mt-3">
                          <div class="card cardArchive card-radius-modul">
                            <div class="card-header card-accoridionModul background-archive-modules" id="headingOne">
                              <h5 class="mb-0">
                                <div class="row">
                                  <div class="col-lg-2 col-md-2 col-sm-2 mr-15">
                                    <img src="../assets/images/1c.png" alt="png" />
                                  </div>
                                  <div class="col-lg-9 col-md-9 col-sm-9 p-20-1199">
                                    <h4 class="title-modul">
                                      Бухгалтерия, Хозрасчет, редакция 1.3
                                    </h4>
                                    <h6>Версия: 3.3</h6>
                                    <p class="info-modul">
                                      {{ translation.translate('Modules', 'information-passage4-1-thin') }}
                                    </p>
                                    <div class="d-flex justify-content-end">
                                      <div>
                                        <button class=" btn-More-details" type="button" data-toggle="collapse"
                                          data-target="#collapseExample3" aria-expanded="false"
                                          aria-controls="collapseExample3">
                                          {{ translation.translate('Modules', 'btn-more-info') }}
                                        </button>
                                      </div>
                                      <div>
                                        <a href="https://edo.faktura.uz/resource/Faktura_uz_Module_3.3_fat.zip" download
                                          class=" btn-download-1c">{{ translation.translate("Resources", "download-btn") }}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </h5>
                            </div>

                            <div class="collapse" id="collapseExample3">
                              <div class="card card-body shadow-none">
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage4-2-thin') }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <div class="col-lg-6">
                <div class="col-lg-12">
                  <div class="card card-100 card-radius-modul">
                    <div class="card-header card-accoridionModul background-archive-modules" id="headingOne">
                      <h5 class="mb-0">
                        <div class="row">
                          <div class="col-lg-2 col-md-2 col-sm-2 mr-15">
                            <img src="../assets/images/1c.png" alt="png" />
                          </div>
                          <div class="col-lg-9 col-md-9 col-sm-9 p-20-1199">
                            <h4 class="title-modul">
                              Бухгалтерия для Узбекистана, редакция 3.0
                            </h4>
                            <h6>Версия: 3.6.9</h6>
                            <p class="info-modul">
                              {{ translation.translate('Modules', 'information-passage2_v3.6-1') }}
                            </p>
                            <div class="d-flex justify-content-end">
                              <div>
                                <button class=" btn-More-details" type="button" data-toggle="collapse"
                                  data-target="#collapseArchive1" aria-expanded="false" aria-controls="collapseArchive1 ">
                                  Archive
                                </button>
                              </div>
                              <div>
                                <button class=" btn-More-details" type="button" data-toggle="collapse"
                                  data-target="#collapseExample4" aria-expanded="false" aria-controls="collapseExample4">
                                  {{ translation.translate("Modules", "btn-more-info") }}
                                </button>
                              </div>
                              <div>
                                <a href="https://edo.faktura.uz/resource/Faktura_uz_ЭДО_Версия_3_6_9_Тонкий.zip" download
                                  class=" btn-download-1c">{{ translation.translate("Resources", "download-btn") }}
                                </a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </h5>
                    </div>

                    <div class="collapse" id="collapseExample4">
                      <div class="card card-body shadow-none">
                        <p class="info-modul">
                          {{
                            translation.translate(
                              "Modules",
                              "information-passage2_v3.6-2"
                            )
                          }}
                        </p>
                        <p class="info-modul">
                          {{
                            translation.translate(
                              "Modules",
                              "information-passage2_v3.6-3"
                            )
                          }}
                        </p>
                        <p class="info-modul">
                          {{
                            translation.translate(
                              "Modules",
                              "information-passage2_v3.6-4"
                            )
                          }}
                        </p>
                      </div>
                    </div>
                    <div class="collapse" id="collapseArchive1">
                      <div class="card-body">
                        <div class="col-lg-12 mt-3">
                          <div class="card cardArchive card-radius-modul">
                            <div class="card-header card-accoridionModul background-archive-modules" id="headingOne">
                              <h5 class="mb-0">
                                <div class="row">
                                  <div class="col-lg-2 col-md-2 col-sm-2 mr-15">
                                    <img src="../assets/images/1c.png" alt="png" />
                                  </div>
                                  <div class="col-lg-9 col-md-9 col-sm-9 p-20-1199">
                                    <h4 class="title-modul">
                                      Управление торговлей, редакция 11
                                    </h4>
                                    <h6>Версия: 3.5</h6>
                                    <p class="info-modul">
                                      {{ translation.translate('Modules', 'information-passage-1') }}
                                    </p>
                                    <div class="d-flex justify-content-end">
                                      <div>
                                        <button class=" btn-More-details" type="button" data-toggle="collapse"
                                          data-target="#collapseExample5" aria-expanded="false"
                                          aria-controls="collapseExample5">
                                          {{ translation.translate("Modules", "btn-more-info") }}
                                        </button>
                                      </div>
                                      <div>
                                        <a href="https://edo.faktura.uz/resource/Faktura_uz_ЭДО_Версия_3_6_9_Тонкий.zip"
                                          download
                                          class=" btn-download-1c">{{ translation.translate("Resources", "download-btn") }}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </h5>
                            </div>

                            <div class="collapse" id="collapseExample5">
                              <div class="card card-body shadow-none">
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage-2') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage-3') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage-4') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage-5') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage-6') }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 mt-3">
                          <div class="card cardArchive card-radius-modul">
                            <div class="card-header card-accoridionModul background-archive-modules" id="headingOne">
                              <h5 class="mb-0">
                                <div class="row">
                                  <div class="col-lg-2 col-md-2 col-sm-2 mr-15">
                                    <img src="../assets/images/1c.png" alt="png" />
                                  </div>
                                  <div class="col-lg-9 col-md-9 col-sm-9 p-20-1199">
                                    <h4 class="title-modul">
                                      Управление торговлей, редакция 11
                                    </h4>
                                    <h6>Версия: 3.4.1</h6>
                                    <p class="info-modul">
                                      {{ translation.translate('Modules', 'information-passage2-1') }}
                                    </p>
                                    <div class="d-flex justify-content-end">
                                      <div>
                                        <button class=" btn-More-details" type="button" data-toggle="collapse"
                                          data-target="#collapseExample5" aria-expanded="false"
                                          aria-controls="collapseExample5">
                                          {{ translation.translate("Modules", "btn-more-info") }}
                                        </button>
                                      </div>
                                      <div>
                                        <a href="https://edo.faktura.uz/resource/Faktura_uz_ЭДО_Версия_3_4_1 Тонкий.zip"
                                          download
                                          class=" btn-download-1c">{{ translation.translate("Resources", "download-btn") }}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </h5>
                            </div>

                            <div class="collapse" id="collapseExample5">
                              <div class="card card-body shadow-none">
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-2') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-3') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-4') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-5') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-6') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-7') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage2-8') }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 mt-3">
                          <div class="card cardArchive card-radius-modul">
                            <div class="card-header card-accoridionModul background-archive-modules" id="headingOne">
                              <h5 class="mb-0">
                                <div class="row">
                                  <div class="col-lg-2 col-md-2 col-sm-2 mr-15">
                                    <img src="../assets/images/1c.png" alt="png" />
                                  </div>
                                  <div class="col-lg-9 col-md-9 col-sm-9 p-20-1199">
                                    <h4 class="title-modul">
                                      Управление торговлей, редакция 11
                                    </h4>
                                    <h6>Версия: 3.3</h6>
                                    <p class="info-modul">
                                      {{ translation.translate('Modules', 'information-passage3-1') }}
                                    </p>
                                    <div class="d-flex justify-content-end">
                                      <div>
                                        <button class=" btn-More-details" type="button" data-toggle="collapse"
                                          data-target="#collapseExample6" aria-expanded="false"
                                          aria-controls="collapseExample6">
                                          {{ translation.translate("Modules", "btn-more-info") }}
                                        </button>
                                      </div>
                                      <div>
                                        <a href="https://edo.faktura.uz/resource/Faktura_uz_ЭДО_Версия_3_3_Тонкий.zip"
                                          download
                                          class=" btn-download-1c">{{ translation.translate("Resources", "download-btn") }}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </h5>
                            </div>

                            <div class="collapse" id="collapseExample6">
                              <div class="card card-body shadow-none">
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage3-2') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage3-3') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage3-4') }} </p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage3-5') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage3-6') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage3-7') }}</p>
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage3-8') }}</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-lg-12 mt-3">
                          <div class="card cardArchive card-radius-modul">
                            <div class="card-header card-accoridionModul background-archive-modules" id="headingOne">
                              <h5 class="mb-0">
                                <div class="row">
                                  <div class="col-lg-2 col-md-2 col-sm-2 mr-15">
                                    <img src="../assets/images/1c.png" alt="png" />
                                  </div>
                                  <div class="col-lg-9 col-md-9 col-sm-9 p-20-1199">
                                    <h4 class="title-modul">
                                      Управление торговлей, редакция 11
                                    </h4>
                                    <h6>Версия: 3.1</h6>
                                    <p class="info-modul">
                                      {{ translation.translate('Modules', 'information-passage4-1-thin') }}
                                    </p>
                                    <div class="d-flex justify-content-end">
                                      <div>
                                        <button class=" btn-More-details" type="button" data-toggle="collapse"
                                          data-target="#collapseExample7" aria-expanded="false"
                                          aria-controls="collapseExample7">
                                          {{ translation.translate('Modules', 'btn-more-info') }}
                                        </button>
                                      </div>
                                      <div>
                                        <a href="https://edo.faktura.uz/resource/Faktura_uz_ЭДО_Версия_3_1_Тонкий.zip"
                                          download
                                          class=" btn-download-1c">{{ translation.translate("Resources", "download-btn") }}
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </h5>
                            </div>

                            <div class="collapse" id="collapseExample7">
                              <div class="card card-body shadow-none">
                                <p class="info-modul">{{ translation.translate('Modules', 'information-passage4-2-thin') }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <mainfooter></mainfooter>
  </div>
</template>

<script defer>
import translate from "../../translation/translate";
import mainbox from "./mainbox.vue";
import mainheader from "./mainheader.vue";
import mainfooter from "./mainfooter.vue";
import ChooseTariffModal from "./modal/ChooseTariffModal.vue";

export default {
  name: "modules",
  data() {
    return {
      translation: translate,
    };
  },
  components: {
    mainheader,
    mainbox,
    mainfooter,
    ChooseTariffModal
  },
  metaInfo() {
    return {
      title: "Интеграция 1с с интернет банкингом и системой Faktura",
      meta: [
        {
          name: "description",
          content:
            "Интеграция 1с с интернет банкингом и системой Faktura, по всем вопросам обращайтесь по телефону: ☎️: +998 (71) 200-00-13.",
        },
      ],
    };
  },
};
</script>

<style>.wt-100 {
  width: 100% !important;
}</style>